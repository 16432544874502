import React from "react";

export default function FaqComponent() {
    return (
        <div className="flex items-center justify-center mt-10 md:mt-20">
            <div className="">
                <div className="flex flex-col  px-4 ">
                    
                    <div className="space-y-5">
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            When will PayTent be available ?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            PayTent will be available  in the early second quarter of 2023. You can join the waitlist to be notified when we launch.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How do I use PayTent ?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Download the chrome extension when it is available, signup or sign in to PayTent from the Chrome extension <br/>then sign into Sage Business Cloud on Google Chrome and PayTent will display all your unpaid invoices,<br/> allowing you request for payment.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            How does PayTent work with Sage Business Cloud?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            PayTent seamlessly integrates with Sage Business Cloud, allowing for real-time payment allocation and automatic<br/> receipt generation within the platform. Our team can assist with the setup process to ensure a smooth setup.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            Can I use PayTent with other accounting software?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            PayTent is specifically designed to integrate with Sage Business Cloud, we are working on integrating other<br/> cloud based accounting software.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            Can PayTent handle multiple currencies?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            At the moment PayTent only supports Naira. Our goal is to provide a global solution for businesses with international <br/>customers, supporting multi currency transactions is on our roadmap.                             </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            Can I track the status of payments made through PayTent?                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Yes, PayTent provides list of inflow payment on the Chrome extension and on Sage Business Cloud,<br/> allowing you to stay informed on the status of all payments.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            Is there a limit to the number of invoices I can add payment to ?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            No, PayTent does not have a limit on the number of invoices that can be created.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl my-shadow bg-white">
                            <summary className="py-6 px-6 md:w-[996px] font-semibold">
                            What Device can I use PayTent on ?                          </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            PayTent is a Google Chrome extension and is available for desktop version of Google Chrome browser.<br/> We are working on making PayTent available for other web browsers like Safari, Oracle and Firefox.                            </p>
                        </details>
                      
                    </div>
                </div>
            </div>
        </div>
    );
}