import { useState } from "react";
import PaytentLogoWhite from "../../assets/img/paytentLogo-white.png";
import GreenBlur from "../../assets/img/greenBlur.png";
import WhiteBlur from "../../assets/img/whiteBlur.png";
import { FiCheckCircle } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import FaqComponent from "../../components/Accordion";
import Footer from "../../components/Footer";
import hamburger from "../../assets/img/hamburger.svg";
import close from "../../assets/img/X.svg";

const Pricing = () => {
  const navBar = [
    { title: "Home", url: "/" },
    { title: "Pricing", url: "pricing" },
    { title: "About", url: "about" },
    { title: "Contact", url: "contact" },
  ];
  const [nav, setNav] = useState(false);

  const handleClick = () => {
    setNav(!nav);
  };

  return (
    <div>
      <div className="bg-primary">
        <nav className="hidden md:flex justify-between items-center py-3 text-white container mx-auto">
          <div className="flex items-center">
            <a href="/">
              <img src={PaytentLogoWhite} alt="Paytent Logo" />
            </a>
            <ul className="flex mx-20">
              {navBar.map(({ title, url }, idx) => (
                <li key={idx}>
                  <a href={url} className="mx-3 hover:opacity-70">
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <a
              href="/waitlist"
              className="bg-white py-2 px-8 rounded-full text-secondary hover:opacity-70 duration-300"
            >
              Get Started
            </a>
          </div>
        </nav>
      </div>

      <div className="container mx-auto bg-primary vh-10 flex md:hidden items-center justify-between px-3 ">
        <div className="z-30">
          <a href="/">
            <img src={PaytentLogoWhite} alt="logo" width="" />
          </a>
        </div>
        <div onClick={handleClick} className="cursor-pointer p-3 z-30">
          {!nav ? (
            <img src={hamburger} alt="logo" />
          ) : (
            <img src={close} alt="" />
          )}
        </div>
      </div>
      <div
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 bg-white w-full h-screen flex flex-col justify-center items-center z-20"
        }
      >
        <ul className="text-3xl font-bold text-center">
          {navBar.map(({ title, url }, idx) => (
            <li key={idx} className="py-5">
              <a href={url} onClick={handleClick}>
                {title}
              </a>
            </li>
          ))}
        </ul>
        <a
          href="/waitlist"
          className="bg-primary py-2 px-8 mt-10 rounded-full text-white hover:opacity-70 duration-300"
        >
          Get Started
        </a>
      </div>


      <section id="pricing relative">
        <div className="bg-primary text-center text-white py-32 relative">
          <img
            src={WhiteBlur}
            alt=""
            className="hidden absolute bottom-0 left-0"
          />
          <h2 className="text-5xl">
            Seamless Solutions , <span className="font-bold">Simplified</span>
          </h2>
          <h1 className="text-6xl font-bold my-5 pb-14">Pricing</h1>

          <img
            src={GreenBlur}
            alt=""
            className="hidden md:block absolute bottom-0 right-0"
          />
        </div>
      </section>
      <section className="flex justify-center relative h-screen mb-52">
        <div className="bg-white text-secondary p-10 mx-5 md:w-3/6 pricing-style absolute -top-32">
          <p className="text-lg">
            Our pricing is designed to provide you with the flexibility and
            affordability you need to optimise your invoicing workflow.
          </p>
          <div className="text-center my-14">
            <p className="font-bold text-4xl md:text-7xl mb-2">Coming soon</p>
            {/* <p className="font-bold text-4xl md:text-7xl mb-2">
              ₦7,600
              <span className="font-medium md:text-5xl opacity-60">
                /monthly
              </span>
            </p>
            <p className="font-semibold text-left md:ml-20">
              + 2% Per transaction
            </p> */}
          </div>

          <p className="font-bold text-3xl leading-none">Features</p>
          <p className="my-3 pb-6 opacity-70">
            Enjoy the full range of features that PayTent has to offer,
            <br /> including:
          </p>
          <div>
            <div className="flex items-center my-5">
              <FiCheckCircle size="26" className="opacity-70" />
              <p className="ml-8 md:text-xl">PayTent Wallet</p>
              <button className="mx-2">
                <AiOutlineInfoCircle size="28" />
              </button>
            </div>
            <div className="flex items-center my-4">
              <FiCheckCircle size="26" className="opacity-70" />
              <p className="ml-8 md:text-xl">Payment links on each invoice</p>
              <button className="mx-2">
                <AiOutlineInfoCircle size="28" />
              </button>
            </div>
            <div className="flex items-center my-4">
              <FiCheckCircle size="26" className="opacity-70" />
              <p className="ml-8 md:text-xl">Automatic allocation of payments</p>
              <button className="mx-2">
                <AiOutlineInfoCircle size="28" />
              </button>
            </div>
            <div className="flex items-center my-4">
              <FiCheckCircle size="26" className="opacity-70" />
              <p className="ml-8 md:text-xl">Automatic receipt generation</p>
              <button className="mx-2">
                <AiOutlineInfoCircle size="28" />
              </button>
            </div>
            <div className="flex items-center my-4">
              <FiCheckCircle size="26" className="opacity-70" />
              <p className="ml-8 md:text-xl">Unlimited invoices</p>
              <button className="mx-2">
                <AiOutlineInfoCircle size="28" />
              </button>
            </div>
          </div>
          <a href="/" className="text-white text-xl">
            <div className="mt-16 text-sm md:text-xl text-center font-bold bg-secondary rounded-full py-6 hover:bg-opacity-90 duration-300">
              Get started with PayTent
            </div>
          </a>
        </div>
      </section>

      <section id="faq">
        <div className="text-secondary text-center container mx-auto mb-32">
          <h1 className="font-bold text-4xl md:text-6xl ">
            Frequently asked questions
          </h1>
          <div>
            <FaqComponent />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Pricing;
