import Landing from './pages/landing/landing';
import './App.css'
import Pricing from './pages/pricing/pricing';
import Contact from './pages/contact/contact';
import Aboutus from './pages/aboutUs/aboutus';
import Waitlist from './pages/waitlist/waitlist';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const { Routes, Route } = require("react-router-dom");

function App() {
  return (
    <>
     <ToastContainer />
      <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/waitlist" element={<Waitlist />} />
        </Routes>
      
    </>
  );
}

export default App;
