import React from "react";
import PaytentLogo from "../../assets/img/paytentLogo.png";
import PaytentLogoWhite from "../../assets/img/paytentLogo-white.png";
import Automation from "../../assets/img/automationImg.png";
import Browser from "../../assets/img/browserImg.png";
import Request from "../../assets/img/requestImg.png";
import Banking from "../../assets/img/bankingImg.png";
import Sage from "../../assets/img/sageLogo.png";
import connection from "../../assets/img/Connection.svg";
import PaytentDash from "../../assets/img/paytentDash.png";
import PaytentHero from "../../assets/img/paytentHero-bg.png";
import Stars from "../../assets/img/Stars.svg";
import star from "../../assets/img/star.svg";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Landing = () => {
  return (
    <div className="">
      <Navbar />
      <main>
        <section className="h-full text-secondary relative py-10 md:pt-32">
          <img src={PaytentHero} alt="" className="hidden md:block absolute -z-10" />
          <div className="container mx-auto grid md:grid-cols-2 items-center">
            <div className="">
              <h1 className="font-bold text-4xl md:text-[55px] leading-tight">
              Payment integration for Sage Business Cloud.              </h1>
              <p className="my-14 md:text-2xl">
                PayTent enhances the account receivable capabilities of Sage Business Cloud by attaching payments to invoices and instantly generating as well as allocating receipts automatically.
              </p>
   
                <a href="/waitlist" className="bg-primary border-2 border-primary rounded-lg text-white px-6 py-4 hover:opacity-90 duration-300">
                  Join Waitlist
                </a>
            </div>
            <div className="flex justify-end">
              <img
                src={PaytentDash}
                alt="product app illustration"
                className="w-11/12 mt-8"
                width="670px"
              />
            </div>
          </div>
          <div className="container mx-auto bg-white my-shadow rounded-xl text-center py-10 mt-16">
            <h2 className="font-bold text-3xl">
              Integrate PayTent with Sage
            </h2>
            <p className="opacity-60 mt-3">
              Download the chrome extension to get started.
            </p>
            <div className="flex justify-center my-8">
              <img src={PaytentLogo} alt="" />
              <img src={connection} alt="" className="mx-10" />
              <img src={Sage} alt="" />
            </div>
            <a
              href="/waitlist"
              className="bg-primary py-3 px-10 rounded-full font-bold text-white hover:opacity-70 duration-300"
            >
              Download
            </a>
          </div>
        </section>

        <section className="text-secondary bg-white">
          <div className="container mx-auto py-24">
            <div className="grid md:grid-cols-2 items-center">
              <img src={Request} alt="Request for payments" />
              <div className="mt-4 md:mx-12">
              <h1 className="font-bold text-3xl md:text-5xl leading-snug">
              Get paid fast and with ease.
              </h1>
              <p className="my-8">
              Easily create payment links on your invoices with just a click. Provide your customers with a convenient and secure way to make payments.              </p>
              <a
              href="/contact"
              className="bg-primary py-3 px-10 rounded-full font-bold text-white hover:opacity-70 duration-300"
            >
              Learn More
            </a>
            </div>
            </div>
          </div>
        </section>

       

        
        <section className="text-secondary">
          <div className="container mx-auto py-10 md:py-24">
            <div className="grid md:grid-cols-2 items-center">
              <div className="mb-6 md:mx-12">
              <h1 className="font-bold text-3xl md:text-5xl leading-snug">
              Automatic receipt generation and allocation
              </h1>
              <p className="my-8">
              PayTent automatically generates receipts immediately payments is received, saving time and improving efficiency.            </p>
              <a
              href="/contact"
              className="bg-primary py-3 px-10 rounded-full font-bold text-white hover:opacity-70 duration-300"
            >
              Learn More
            </a>
            </div>
            <img src={Automation} alt="Request for payments" />
            </div>
          </div>
        </section>

        <section className="text-secondary bg-white">
          <div className="container mx-auto py-10 md:py-36">
            <div className="grid md:grid-cols-2 items-center">
              <img src={Banking} alt="payments automation" />
              <div className="mt-6 md:mx-12">
              <h1 className="font-bold text-3xl md:text-5xl leading-snug">
              Real time banking
              </h1>
              <p className="my-8">
              PayTent seamlessly integrates with the banking module of Sage Business Cloud, providing a real time view of your bank balance and transactions.            </p>
              <a
              href="/contact"
              className="bg-primary py-3 px-10 rounded-full font-bold text-white hover:opacity-70 duration-300"
            >
              Learn More
            </a>
            </div>
            </div>
          </div>
        </section>
 
 <section className="text-secondary bg-primary bg-opacity-10">
        <div className="container mx-auto py-20 text-center ">
        <h1 className="font-bold text-3xl md:text-5xl">
        Install PayTent on Google Chrome
              </h1>
              <p className="my-4">
              Experience the ease of PayTent on Sage Business Cloud through Google Chrome.<br/> Download the extension to get started.  </p>
              <div className="flex justify-center my-10">
                <div className="-mt-10 mr-10">
              <img src={Stars} alt=" illustration" />
              <img src={star} alt=" illustration" className="mt-40"/>
                </div>
              <img src={Browser} alt="browser illustration" />
              <div className="mt-20 ml-10">
              <img src={star} alt=" illustration" />
              <img src={Stars} alt=" illustration" className="mt-40"/>
              </div>
              </div>
              <a
              href="/waitlist"
              className="bg-primary py-3 px-10 rounded-full font-bold text-white hover:opacity-70 duration-300"
            >
              Download
            </a>
        </div>
        </section>
      
        <section className="text-white bg-[#0D0E10]">
        <div className="container mx-auto py-24 text-center ">
            <div className="flex justify-center">
                <img src={PaytentLogoWhite} alt="Paytent logo" />
            </div>
        <h1 className="font-semibold text-2xl md:text-4xl my-10 leading-snug">
        Eliminate errors, get organized and get paid <br/> faster with PayTent.
              </h1>
              
              
                <a href="/waitlist" className="bg-white border-2 border-white rounded text-secondary px-6 py-4 hover:opacity-90 duration-300">
                  Join Waitlist
                </a>
        </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Landing;
