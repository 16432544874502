import PaytentLogo from "../assets/img/paytentLogo.png";

const Footer = () => {
  return (
    <footer className="bg-white text-secondary">
      <div className="container mx-auto hidden md:flex items-start justify-between  py-20 ">
        <div>
          <img src={PaytentLogo} alt="Paytent Logo" />
          <p className="mt-40">Copyright @ 2023 Pay | Tent. </p>
        </div>
        <div className="flex ">
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/about" className="opacity-90 mt-8 mb-2">
              About
            </a>
            <a href="/about" className="opacity-90 mb-2">
              Our team
            </a>
            <a href="/contact" className="opacity-90 mb-2">
              Contact us
            </a>
            <a href="/" className="opacity-90">
              FAQs
            </a>
          </div>
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Socials</h5>
            <a href="/" className="opacity-90 mt-8 mb-2">
              Linkedin
            </a>
            <a href="/" className="opacity-90 mb-2">
              Twitter
            </a>
            <a href="/" className="opacity-90 mb-2">
              Instagram
            </a>
           
          </div>
         
        </div>
      </div>
      <div className="container mx-auto md:hidden flex flex-col items-start justify-between  py-20 ">
        <div className="mb-5">
          <img src={PaytentLogo} alt="Paytent Logo" />
        </div>
        <div className="flex ">
          <div className="m-5 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/about" className="opacity-90 mt-8 mb-2">
              About
            </a>
            <a href="/about" className="opacity-90 mb-2">
              Our team
            </a>
            <a href="/contact" className="opacity-90 mb-2">
              Contact us
            </a>
            <a href="/" className="opacity-90">
              FAQs
            </a>
          </div>
          <div className="m-5 flex flex-col">
            <h5 className="font-bold">Company</h5>
             <a href="/" className="opacity-90 mt-8 mb-2">
              Linkedin
            </a>
            <a href="/" className="opacity-90 mb-2">
              Twitter
            </a>
            <a href="/" className="opacity-90 mb-2">
              Instagram
            </a>
           
          </div>
          
        </div>
            <p className="mt-10">Copyright @ 2023 Pay | Tent. </p>
      </div>
    </footer>
  );
};

export default Footer;
