import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import axios from "axios";
import Email from "../../assets/img/email.svg";
import Whatsapp from "../../assets/img/whatsapp.svg";
import { toast } from "react-toastify";

const Contact = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [businessIndusty, setBusinessIndusty] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [message, setMessage] = useState("");
  const [reasonForContact, setReasonForContact] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstname,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastname,
        },

        {
          objectTypeId: "0-1",
          name: "company",
          value: companyName,
        },
        {
          objectTypeId: "0-1",
          name: "business_industry",
          value: businessIndusty,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: phone,
        },
        {
          objectTypeId: "0-1",
          name: "state",
          value: state,
        },
        {
          objectTypeId: "0-1",
          name: "city",
          value: city,
        },
        {
          objectTypeId: "0-1",
          name: "reason_for_contact",
          value: reasonForContact,
        },
        {
          objectTypeId: "0-1",
          name: "message",
          value: message,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Lifeeremit to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Lifeeremit.",
            },
          ],
        },
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/26963439/18bf2930-12fa-4e99-a9d1-639045cc6c0b`,
      data
    );
    if (response.status === 200) {
      toast.success("Form submitted. Thank you for contacting us");
    } else {
      toast.error("Please fill input fields with (*)");
    }

    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setCompanyName("");
    setBusinessIndusty("");
    setReasonForContact("");
    setCity("");
    setState("");
    setMessage("");

    // window.location.reload();
  };

  return (
    <div>
      <Navbar />
      <div className="h-full pt-40 container mx-auto text-secondary">
        <div className="grid md:grid-cols-2 gap-5">
          <div className="bg-white shadow-md w-100 rounded-xl p-8 mb-10">
            <h1 className="text-4xl md:text-5xl font-semibold l">
              Get in touch
            </h1>
            <form>
              <div className="mt-4"></div>
              <div className="mt-8">
                <label for="firstname">
                  First Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="firstname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="lastname">
                  Last Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="lastname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setLastname(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="companyname">
                  Company Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="companyname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="industry">Business Industry:</label>
                <input
                  id="email"
                  type="text"
                  placeholder="e.g Banking"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setBusinessIndusty(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="phonenumber">Phone Number:</label>
                <input
                  id="phonenumber"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="email">
                  Email:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="email"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="state">State / Region:</label>
                <input
                  id="state"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="city">City:</label>
                <input
                  id="city"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>

              <div className="mt-4">
                <label for="reasons">
                  Reasons For Contact:<sup className="mx-1">*</sup>
                </label>
                <select
                  for="reasons"
                  className="px-3 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setReasonForContact(e.target.value)}
                >
                  <option value="">-Select-</option>
                  <option value="Demo">Demo</option>
                  <option value="Career">Career</option>
                  <option value="Inquiry">Inquiry</option>
                  <option value="Support">Support</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="mt-4">
                <label for="details">
                  Details:<sup className="mx-1">*</sup>
                </label>
                <textarea
                  id="details"
                  className="p-4 mt-2 w-full border border-secondary border-opacity-40"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                onClick={submit}
                className="mt-6 text-center w-full font-bold bg-primary text-white  py-3 hover:bg-opacity-90 duration-300"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="p-5">
            <div>
              <h1 className="text-3xl md:text-5xl font-semibold leading-snug">
                Got some questions about Pay | Tent?
              </h1>
              <p className="mt-8">
                We are excited to hear from you and we pride ourself in our
                response time.
              </p>
              <div className="mt-10 flex items-center">
                <img src={Email} alt="" />
                <div className="mx-5">
                  <p className="font-semibold">Email</p>
                  <a href="mailto:info@paytent.io">info@paytent.io</a>
                </div>
              </div>
              <div className="mt-6 flex items-center">
                <img src={Whatsapp} alt="" />
                <div className="mx-5">
                  <p className="font-semibold">Whatsapp</p>
                  <p>+2348080187158</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
