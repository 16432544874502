import Navbar from "../../components/Navbar";
import Idea from "../../assets/img/Idea.svg";
import Vision from "../../assets/img/Vision.svg";
import Footer from "../../components/Footer";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import Ronma from "../../assets/img/ronma.png";
import Doyin from "../../assets/img/doyin.png";
import Samad from "../../assets/img/samad.png";

const Aboutus = () => {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto text-secondary md:pt-40">
        <h1 className="font-semibold text-3xl md:text-5xl leading-tight">
          We are on a mission to make <br className="hidden md:block" /> your{" "}
          <span className="text-primary">bookkeeping</span> easier.
        </h1>
        <p className="my-14 font-medium md:text-2xl leading-8 md:w-[610px]">
          In a constantly evolving digital ecosystem, we recognise the monotony
          and repetitiveness of manual accounting processes, we want to help you
          fix that.
        </p>
        <div className="my-20 flex flex-col md:flex-row justify-between">
          <div className="w-100 md:w-[562px] my-3 p-10 our-mission bg-[#FDA736] bg-opacity-10">
            <img src={Idea} alt="" />
            <p className="font-semibold text-4xl mt-3">Our Mission</p>
            <p className="font-medium text-xl mt-8">
              Helping businesses grow and evolve by enhancing their tools and
              processes.
            </p>
          </div>
          <div className="w-100 md:w-[562px] my-3 p-10 our-mission bg-primary bg-opacity-10">
            <img src={Vision} alt="" />
            <p className="font-semibold text-4xl mt-3">Our vision</p>
            <p className="font-medium text-xl mt-8">
              At Pay | Tent, we are creating an enabling experience that
              positively impacts our customers and transforms them into our
              lifetime advocates.
            </p>
          </div>
        </div>
        <p className="font-semibold text-4xl mt-36">Our team</p>
        <p className="font-medium text-xl mt-2">
          We are tight knit team distributed all around the globe.{" "}
        </p>
        <div className="my-16 flex flex-col md:flex-row justify-between">
          <div className="md:w-[384px] rounded-2xl my-2">
            <img src={Ronma} alt="" />
            <div className="bg-white py-3 text-center rounded-b-[20px]">
              <p className="text-2xl font-semibold">Adebiyi Ronma Adedeji</p>
              <p className="text-lg">Founder | Sales & Operations</p>
              <hr className="opacity-20 mt-5" />
              <div className="flex justify-center py-2 opacity-90">
                <a href="https://www.linkedin.com/in/ronma" className="mx-2">
                  <AiFillLinkedin size="36" />
                </a>
                <a href="/" className="mx-2">
                  <AiFillTwitterCircle size="36" />
                </a>
              </div>
            </div>
          </div>
          <div className="md:w-[384px] rounded-2xl my-2">
            <img src={Doyin} alt="" />
            <div className="bg-white py-3 text-center rounded-b-[20px]">
              <p className="text-2xl font-semibold">Olarewaju Doyin</p>
              <p className="text-lg">Co-Founder | Tech Lead</p>
              <hr className="opacity-20 mt-5" />
              <div className="flex justify-center py-2 opacity-90">
                <a
                  href="https://www.linkedin.com/in/olarewaju-doyin"
                  className="mx-2"
                >
                  <AiFillLinkedin size="36" />
                </a>
                <a href="/" className="mx-2">
                  <AiFillTwitterCircle size="36" />
                </a>
              </div>
            </div>
          </div>
          <div className="md:w-[384px] rounded-2xl my-2">
            <img src={Samad} alt="" />
            <div className="bg-white py-3 text-center rounded-b-[20px]">
              <p className="text-2xl font-semibold">Samad Azeez</p>
              <p className="text-lg">Frontend | Product Design</p>
              <hr className="opacity-20 mt-5" />
              <div className="flex justify-center py-2 opacity-90">
                <a href="https://www.linkedin.com/in/samadazeez/" className="mx-2">
                  <AiFillLinkedin size="36" />
                </a>
                <a href="/" className="mx-2">
                  <AiFillTwitterCircle size="36" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
