import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import axios from "axios";
import {  toast } from 'react-toastify';

const Waitlist = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessIndustry, setBusinessIndusty] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [message, setMessage] = useState("");


  const submit = async (e) => {
    e.preventDefault();

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstname,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastname,
        },

        {
          objectTypeId: "0-1",
          name: "company",
          value: companyName,
        },
        {
          objectTypeId: "0-1",
          name: "business_industry",
          value: businessIndustry,
        },
        {
          objectTypeId: "0-1",
          name: "phone",
          value: phone,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "state",
          value: state,
        },
        {
          objectTypeId: "0-1",
          name: "message",
          value: message,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Lifeeremit to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Lifeeremit.",
            },
          ],
        },
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/26963439/676d7c23-05fc-4c1f-8022-360920170a48`,
      data
    )
    if (response.status === 200) {
      toast.success("Form submitted. Thank you for Joining our waitlist");
    } else {
      toast.error("Please fill input fields with (*)");
    }
     console.log(response)
  

    setFirstname("");
    setLastname("");
    setEmail("");
    setCompanyName("");
    setBusinessIndusty("");
    setPhone("");
    setState("");
    setMessage("");

    // window.location.reload();
  };
  return (
    <div>
      <Navbar />
      <div className="h-full pt-40 container mx-auto text-secondary">
        <div className="grid md:grid-cols-2 gap-5">
          <div className="bg-white shadow-md w-100 rounded-xl p-8 mb-10">
            <h1 className="text-4xl md:text-5xl font-semibold l">
              Join waitlist
            </h1>
            <form>
              <div className="mt-4"></div>
              <div className="mt-8">
                <label for="firstname">
                  First Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="firstname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="lastname">
                  Last Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="lastname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setLastname(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="companyname">
                  Company Name:<sup className="mx-1">*</sup>
                </label>
                <input
                  id="companyname"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label for="industry">Business Industry:</label>
                <input
                  id="email"
                  type="text"
                  placeholder="e.g Banking"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setBusinessIndusty(e.target.value)}
                />
              </div>

              <div className="mt-4">
                <label for="email">Phone:</label>
                <input
                  id="email"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="email">Email:<sup className="mx-1">*</sup></label>
                <input
                  id="email"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="state">State / Region:</label>
                <input
                  id="state"
                  type="text"
                  className="p-4 mt-2 w-full h-[46px] border border-secondary border-opacity-40"
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <label for="details">
                  Message:<sup className="mx-1">*</sup>
                </label>
                <textarea
                  id="details"
                  className="p-4 mt-2 w-full border border-secondary border-opacity-40"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                onClick={submit}
                className="mt-6 text-center w-full font-bold bg-primary text-white  py-3 hover:bg-opacity-90 duration-300"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="p-5">
            <div>
              <h1 className="text-3xl md:text-5xl font-semibold leading-snug">
                Be the first to experience PayTent
              </h1>
              <p className="mt-8">
                Join our Paytent waitlist and be the first to experience
                effortless payment management with Sage Business Cloud
                integration. Get exclusive access to our launch offer and
                streamline your account receivable process like never before.
                Sign up now to be the first in line!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Waitlist;
